import React, { useEffect, useState, } from "react";
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Alert from 'react-bootstrap/Alert';
import classes from "./App.css";
import Spinner from 'react-bootstrap/Spinner';

let page = 1;


const View = () => {

//  const [data, setData] = useState([]);
  // const location = useLocation()
  // const { data } = location.state;
  // const item = data;
  // alert(item)
  const [loading, setloading] = useState(true);
  const [showdata, setshowdata] = useState(false);
  const [show, setShow] = useState(false);
  const params = useParams();

  const dealid = params.id;

  useEffect(() => { 
    getData() 
   // ff()
 
  }, 
    []);

  const ff = () =>{
    alert("hello")
  }

  const getData = () => {
   // const navigate = useNavigate();
    setloading(true)
    fetch('https://theuniquedeal.com/api/view.php?id='+dealid,
    {
  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      //  'Authorization': 'bearer ' + this.state.Token,
      },
      // body: JSON.stringify({
  
      //   email: UserEmail,
  
      //   password: UserPassword
  
      // })
  
    })
    .then((response) => response.json())
    .then((responseJson) => {
  
      console.log(responseJson)
      if(responseJson.length>0){
     //   alert(responseJson[0].link)
 //    navigate(responseJson[0].link);
 window.location.replace(responseJson[0].link);

      setloading(false)
      setshowdata(false)
      }
      else{
        setloading(false)
        setshowdata(true)
      }
    }).catch((error) => {
      console.error(error);
    });
  }
  const navigate = useNavigate();

  const handleClick = () => {
  
  }

  return (
     <main style={{display:'flex', backgroundColor:'#f6f6f6', padding:20, alignSelf:'center', justifyContent:'center', height:'100vh'}}>

        <Container
        style={{marginTop:'30vh'}}
        >
      {loading?
      <Container>
     <div style={{display:'flex', justifyContent:'center', margin:20}}>
     <Spinner variant="primary" animation="border" role="status">
     <span className="visually-hidden">Loading...</span>
   </Spinner>

   </div>
   <h6
   style={{
    textAlign:'center'
   }}
   >Please while wait while we redirecting to you Deal page</h6>
   </Container>
   :
   null
   }
</Container>

</main>
  );
};

export default View;