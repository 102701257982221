import React, { useEffect, useState, } from "react";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
const Dashboard = () => {

  const navigate = useNavigate();

  const [title, settitle] = useState('');
  const [img, setimg] = useState('');
  const [link, setlink] = useState('');
  const [mrp, setmrp] = useState('');
  const [dealprice, setdealprice] = useState('');
  const [description, setdescription] = useState('');
  const [Isvisible, setIsVisible] = useState(false)

  useEffect(() => { 
    getData() 
    const logindetail = JSON.parse(localStorage.getItem("data"));
  if(logindetail==null){
  //  alert("fff")
    navigate('/Admin/Login');
  }
 
  }, 
    []);
  const getData = async () => {
    
  }


  const handleClick = (event) => {
    event.preventDefault();

//alert(JSON.stringify(d))
    fetch('https://theuniquedeal.com/api/add_deal.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    // 'Authorization': 'bearer ' + this.state.Token,
      },
      body: JSON.stringify({
      
       "title": title,
       "img":img,
       "link":link,
       "mrp":mrp,
       "dealprice":dealprice,
       "description":description
     //  new_password_confirmation:confirmpassword,
       
      })
   
    })
    .then((response) => response.json())
          .then((responseJson) => {
        
         console.log(responseJson)
     if (responseJson.message=='Deal Added Successfully'){
  //    window.location.reload(false);
  setdealprice('')
  setdescription('')
  settitle('')
  setimg('')
  setlink('')
  
  setIsVisible(true)
  const timer = setTimeout(() => {
     setIsVisible(false)
  }, 5000);
  return () => clearTimeout(timer);

    }
     else{
      alert(responseJson.message)
      // Alert.alert(responseJson.errorMsg);
     }
          }).catch((error) => {
            console.error(error);
          });
  }

  const democlick = () =>{
    console.log("demo")
  }

  const signout = () =>{
    
    localStorage.removeItem("data");
    navigate('/Admin/Login');
  }

  return (
     <main style={{flex:1, backgroundColor:'#f6f6f6', padding:20, alignSelf:'center', justifyContent:'center'}}>
{/* <h1
//onClick={this.handleSubmit}
style={{alignSelf:'center'}}
>Dashboard <Button
onClick={signout}
variant="danger" type="submit">
        Sign Out
      </Button></h1> */}
      <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">Dashboard</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link 
            onClick={signout}
            >Sign Out</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

{/* <form 
onSubmit={handleClick}>       
         <h3> Title: </h3>
          <input 
          required={true}
          type="text" 
          style={{height:30, borderWidth:1, borderColor:"lightgray"}}
         // value={this.state.username} 
         onChange={(event) => settitle(event.target.value)}
          />
          <h3> Image Link: </h3>
           <input 
           required={true}
           style={{height:30, borderWidth:1, borderColor:"lightgray"}}
          type="text" 
      //    value={this.state.password} 
      onChange={(event) => setimg(event.target.value)}
          />
              <br/>
              <br/>
        <input style={{backgroundColor:'green', color:'#fff', padding:20}} type="submit" value="Submit" />
     
      </form> */}

      <Form onSubmit={handleClick}>
      <Form.Group className="mb-3" >
        <Form.Label>Title:</Form.Label>
        <Form.Control required onChange={(event) => settitle(event.target.value)} type="text" placeholder="Enter Title" />
       
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>Image Link:</Form.Label>
        <Form.Control required onChange={(event) => setimg(event.target.value)} type="text" placeholder="Image Link" />
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>Deal Link:</Form.Label>
        <Form.Control required onChange={(event) => setlink(event.target.value)} type="text" placeholder="Deal Link" />
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>Mrp:</Form.Label>
        <Form.Control required onChange={(event) => setmrp(event.target.value)} type="text" placeholder="Mrp" />
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>Deal Price:</Form.Label>
        <Form.Control required onChange={(event) => setdealprice(event.target.value)} type="text" placeholder="Deal Price" />
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>Description</Form.Label>
        <Form.Control onChange={(event) => setdescription(event.target.value)} type="text" placeholder="Description" />
      </Form.Group>
   
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>

    {Isvisible?
  <Alert variant={"primary"}>
  Deal Added Successfully
</Alert>
  :
  null
  }

</main>
  );
};

export default Dashboard;