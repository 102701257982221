import React, { useEffect, useState, } from "react";
import { useNavigate, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from 'react-bootstrap/Spinner';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Alert from 'react-bootstrap/Alert';
import classes from "./App.css";
import Modal from 'react-bootstrap/Modal';

let page = 1;
const Admin = () => {

  const [data, setData] = useState([]);
  const [modaldata, setmodalData] = useState('');
  const [refresh, setRefresh] = useState(false)
  const [loading, setloading] = useState(true);
  const [showdata, setshowdata] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => { 
  //  page = 1 ;
   
    getData(setData, data) 
   // ff()
 
  }, 
    []);

  const ff = () =>{
    alert("hello")
  }

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
  //  console.log(item)
    setmodalData(item);
    setShow(true);
  }

  const getData = (setData, data, event) => {
 //   event.preventDefault();
    setloading(true)
    fetch('https://theuniquedeal.com/api/latestdeals.php?page='+page,
    {
  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      //  'Authorization': 'bearer ' + this.state.Token,
      },
      // body: JSON.stringify({
  
      //   email: UserEmail,
  
      //   password: UserPassword
  
      // })
  
    })
    .then((response) => response.json())
    .then((responseJson) => {
  
      console.log(responseJson)
      if(responseJson.value==undefined){
     //   alert(page)
      setData([...data, ...responseJson]);
      setloading(false)
      setshowdata(false)
      page = page + 1;
      }
      else{
        setloading(false)
        setshowdata(true)
      }
    }).catch((error) => {
      console.error(error);
    });
  }
  const navigate = useNavigate();

  const handleClick = () => {
  
  }

  return (
     <main style={{flex:1, backgroundColor:'#f6f6f6', padding:20, alignSelf:'center', justifyContent:'center', alignItems:'center'}}>

<Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home">
            {/* <img
              alt=""
              src="/logo.svg"
              width="30"
              height="30"
              className="d-inline-block align-top"
            /> */}
            {' '}
            The Unique Deal
          </Navbar.Brand>
        </Container>
      </Navbar>
      {/* <img className='img-fluid shadow-4' style={{objectFit:'cover',  maxWidth:'100%', height: 'auto'}} src="https://images-eu.ssl-images-amazon.com/images/G/31/img21/Wireless/Shreyansh/BAU/Unrexc/D70978891_INWLD_BAU_Unrec_Uber_PC_Hero_3000x1200._CB594707876_.jpg" /> */}
      <InfiniteScroll
     dataLength={data.length} //This is important field to render the next data
     next={() => {
       getData(setData, data);
     }}
     hasMore={true}
     loader={
     loading?
     <div style={{display:'flex', justifyContent:'center', margin:20}}>
     <Spinner variant="primary" animation="border" role="status">
     <span className="visually-hidden">Loading...</span>
   </Spinner>
   </div>
  :
  null 
  }
     endMessage={
       setshowdata?
       <p style={{ textAlign: "center" }}>
         <b>Yay! You have seen it all</b>
       </p>
       :
       null
     }
      >
<Container 
//style={{marginTop:'18%'}} 

fluid="md">
<Row>

{data.map((item,i)=>{

  const save = parseInt(item.mrp-item.dealprice)/parseInt(item.mrp)*100;
  const save1 = save.toFixed(0,2)
  return(
    <Col key={i} md={3} xs={12} sm={6}>
    <Card  style={{ width: '18rem', margin:10 }}>
    <Card.Img variant="top" height="300" width="200" style={{alignSelf:'center', objectFit:'contain' }} src={item.img} />
    <Card.Body>
      <Card.Title 
      
      style={{
        whiteSpace: "initial",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
      }}
      >{item.title}</Card.Title>
      <Row>
        <Col md={4} sm={4} xs={4}>
      <Card.Text>
        {item.dealprice}
      </Card.Text>
      </Col>
      <Col md={4} sm={4} xs={4}>
      <Card.Text style={{textDecorationLine:'line-through'}}>
        {item.mrp}
      </Card.Text>
      </Col>
      <Col md={4} sm={4} xs={4}>
      <Card.Text style={{}}>
      {save1} % off
      </Card.Text>
      </Col>
      </Row>
    
      <Row>
      <Col md={6} sm={6} xs={6}>
      <Button variant="primary">
        {/* <a style={{color:'#fff'}} href={item.link} target="_blank" rel="noreferrer">
        Get Deal
      </a> */}
       <Link
      style={{color:'#fff', }}
      to={"/View/"+item.id}
      state={{ data: item }}
      target="_blank"
      >
        Get Deal
        </Link>
      </Button>
      </Col>
      <Col md={6} sm={6} xs={6}>
      <Button
      onClick={handleShow.bind(this, item)}
      style={{color:'#fff'}}
      variant="success">
        {/* <Link
      style={{color:'#fff', }}
      to={"/View/"+item.id}
      state={{ data: item }}
      target="_blank"
      >
        View Detail
        </Link> */}
        View Detail
        </Button>
      </Col>
      </Row>
    </Card.Body>
  </Card>
  </Col>
  )
})}

</Row>
</Container>
</InfiniteScroll>

{showdata?
<Alert variant={'secondary'}>
          No More Data
        </Alert>
        :
        null
        }
 <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modaldata.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <Card.Img variant="top" height="300" width="200" style={{alignSelf:'center', objectFit:'contain' }} src={modaldata.img} />
          {modaldata.description}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
          <a style={{color:'#fff'}} href={modaldata.link} target="_blank" rel="noreferrer">
        Get Deal
      </a>
          </Button>
        </Modal.Footer>
      </Modal>
</main>
  );
};

export default Admin;