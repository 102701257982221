import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './Home';
import Admin from './Admin';
import Dashboard from './Dashboard';
import View from './View';

//import Goodbye from './Goodbye';

const App = () => (
  <Router basename="/">
    <Routes>
      <Route exact path="/" replace element={<Home />} />
      <Route exact path="/Dashboard" replace element={<Dashboard />} />
      <Route exact path="Admin/Login" element={<Admin />} />
      <Route path="/View/:id" element={<View />} />
    </Routes>
  </Router>
);

export default App;