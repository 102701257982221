

const textinputstyle = {
    height:30, 
    borderWidth:1, 
    borderColor:"lightgray",
    alignSelf:'center',
 //   width:"50%"
}

const Container = { 
    display: "flex", 
    flexDirection: "column",
    padding:10, 
    backgroundColor:'#f6f6f6',
    height:'100vh',
 //   justifyContent:'center'
};

const greenbtn = {
    backgroundColor:'green', 
    color:'#fff', 
    padding:20,
}


export const styles = {
  textinputstyle: textinputstyle,
  Container:Container,
  greenbtn:greenbtn,
}

