import React, { useEffect, useState, } from "react";
import { useNavigate } from 'react-router-dom';
import {styles} from './Style';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const Admin = () => {

  const navigate = useNavigate();


  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');


  useEffect(() => { 
    getData() 
  //  localStorage.removeItem("logindetail")
  //  localStorage.setItem("data", JSON.stringify(data));
    const logindetail = JSON.parse(localStorage.getItem("data"));
    if (logindetail!=null) {
      navigate('/Dashboard');
 //  alert(JSON.stringify(logindetail.name))
    }
    else{
  //    navigate('/');
    }
  }, 
    []);
  const getData = async () => {}
  
  const handleClick = () => {
  
   if(username=='theuniquedeal@admin.com' && password=='saurav3406'){
    const data={
      name:username,
      password:password
    }
    localStorage.setItem("data", JSON.stringify(data));
    navigate('/Dashboard');
   }
   else{
     alert("Invalid Username/Password")
   }
   // navigate('/Home');
  }

  return (
     <main style={styles.Container}>
<h1
//onClick={this.handleSubmit}
style={{alignSelf:'center'}}
>Admin Login</h1>
{/* <form 
style={{alignSelf:'center',}}
onSubmit={handleClick}>       
         <h3> UserName: </h3>
          <input 
          required={true}
          type="text" 
          style={styles.textinputstyle}
         // value={this.state.username} 
         onChange={(event) => setusername(event.target.value)}
          />
          <h3
          style={{}}
          > Password: </h3>
           <input 
           required={true}
           style={styles.textinputstyle}
          type="password" 
      //    value={this.state.password} 
      onChange={(event) => setpassword(event.target.value)}
          />
              <br/>
              <br/>
        <input style={styles.greenbtn} type="submit" value="Submit" />
    
      </form> */}

      <Form onSubmit={handleClick}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>User Name</Form.Label>
        <Form.Control required onChange={(event) => setusername(event.target.value)} type="text" placeholder="Enter username" />
       
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control required onChange={(event) => setpassword(event.target.value)} type="password" placeholder="Password" />
      </Form.Group>
   
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>

</main>
  );
};

export default Admin;